import React from 'react'
import ProfileData from '../Components/ProfileData'
import ProfileHistory from '../Components/ProfileHistory'
import './ProfilePage.css'

export default function ProfilePage() {
  return (
    <>
        <ProfileData />
        <ProfileHistory />
    </>
  )
}
