import React from 'react'
import CargoDetailsSection from '../Components/CargoDetailsSection'
import ProfileHistory from '../Components/ProfileHistory'
import './CargoDetailsPage.css'

export default function CargoDetailsPage() {
  return (
    <>
      <CargoDetailsSection />
      <ProfileHistory />
    </>
  )
}
